function Header() {
    return (
      <header>
        <video src="./video.mp4" loop autoPlay muted></video>
        <h1>SRV SOLUTIONS</h1>
      <h3>Success Through Inovation</h3>
          <h3>Rise Above Challenges</h3>
          <h3>Victory With Integrity</h3>
        <div className="row">
          {/* <button className="btn" style={{ cursor: "pointer" }}>
            Sign Up

            
          </button> */}
  
          {/* <button className="btn" style={{ cursor: "pointer" }}>
            Log in
          </button> */}
        </div>
  
        <div className="headerbg"></div>
      </header>
    );
  }
  export default Header;
  